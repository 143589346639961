import { render, staticRenderFns } from "./timer-redirect.html?vue&type=template&id=60bd6944&scoped=true&external"
import script from "./timer-redirect.ts?vue&type=script&lang=ts&external"
export * from "./timer-redirect.ts?vue&type=script&lang=ts&external"
import style0 from "./timer-redirect.scss?vue&type=style&index=0&id=60bd6944&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60bd6944",
  null
  
)

/* custom blocks */
import block0 from "./timer-redirect.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Ftimer-redirect%2Ftimer-redirect.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports