import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export const status = {
  expired: { id: 'expired', name: 'Expired' },
  running: { id: 'running', name: 'Running' },
  upcoming: { id: 'upcoming', name: 'Upcoming' },
};

@Component({ name: 'timer-redirect' })
export default class TimerRedirect extends Vue {
  @Prop()
  private starttime!: Date;
  @Prop()
  private endtime!: Date;
  @Prop()
  private redirectUrl!: string;
  private timer = '';
  @Prop({ default: '' }) private expiredMessage!: string;
  @Prop({ default: '' }) private runningMessage!: string;
  @Prop({ default: '' }) private upcomingMessage!: string;
  private start = 0;
  end = 0;
  interval: any;
  days = 0;
  minutes = 0;
  hours = 0;
  seconds = 0;
  message = '';
  statusType = '';

  mounted() {
    this.start = new Date(this.starttime).getTime();
    this.end = new Date(this.endtime).getTime();
    // Update the count down every 1 second
    this.timerCount(this.start, this.end);
  }
  public startTimer() {
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
  }
  timerCount(start: number, end: number) {
    // Get todays date and time
    const now = new Date().getTime();

    // Find the distance between now an the count down date
    const distance = start - now;
    const passTime = end - now;

    if (distance < 0 && passTime < 0) {
      this.message = this.expiredMessage;
      this.statusType = status.expired.id;
      clearInterval(this.interval);
      this.$emit('expired');
      return;
    } else if (distance < 0 && passTime > 0) {
      this.calcTime(passTime);
      this.message = this.runningMessage;
      this.statusType = status.running.id;
    } else if (distance > 0 && passTime > 0) {
      this.calcTime(distance);
      this.message = this.upcomingMessage;
      this.statusType = status.upcoming.id;
    }
  }
  calcTime(dist: number) {
    // Time calculations for days, hours, minutes and seconds
    this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
  }
}
