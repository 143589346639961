import { Component, Vue, Prop } from 'vue-property-decorator';
import TimerRedirect from '@/components/timer-redirect/timer-redirect.vue';
import { namespace } from 'vuex-class';

const auth = namespace('auth');

@Component({
  name: 'error-access-view',
  components: { TimerRedirect },
})
export default class ErrorAccessView extends Vue {
  get recruiterUrl() {
    return 'home';
  }

  startTime?: Date;
  endTime?: Date;

  created() {
    this.startTime = new Date();
    this.endTime = new Date(this.startTime.getTime() + 30 * 1000);
  }

  mounted() {
    const ref = this.$refs.timerRedirect as Vue;
    (ref as any).startTimer();
  }

  expiredTimer() {
    this.$router.push(this.recruiterUrl);
  }
}
